import { iTab } from '../frameWork/Tabs';
import iProduct from '../../types/product/iProduct';
import ProductDetailsGeneralPanel from './components/ProductDetailsGeneralPanel';
import ProductDetailsAttributesPanel from './components/ProductDetailsAttributesPanel';
import ProductDetailsSuppliersPanel from './components/ProductDetailsSuppliersPanel';
import ProductPriceList from './ProductPriceList';

export enum ProductDetailsTabKeys {
  GENERAL = 'General',
  ATTRIBUTES = 'Attributes',
  SUPPLIERS = 'Suppliers',
  PRICES = 'Prices',
}

type iGetTabItemsMap = {
  product: iProduct;
  isDisabled?: boolean;
  onSaved?: (saved: iProduct) => void;
};
const getTabItemsMap = ({
  product,
  isDisabled,
  onSaved,
}: iGetTabItemsMap): { [key: string]: iTab } => {
  return {
    [ProductDetailsTabKeys.GENERAL]: {
      eventKey: ProductDetailsTabKeys.GENERAL,
      title: ProductDetailsTabKeys.GENERAL,
      tabContent: (
        <ProductDetailsGeneralPanel
          product={product}
          isDisabled={isDisabled}
          onSaved={onSaved}
        />
      ),
    },
    [ProductDetailsTabKeys.ATTRIBUTES]: {
      eventKey: ProductDetailsTabKeys.ATTRIBUTES,
      title: ProductDetailsTabKeys.ATTRIBUTES,
      tabContent: (
        <ProductDetailsAttributesPanel
          product={product}
          isDisabled={isDisabled}
          onSaved={onSaved}
        />
      ),
    },
    ...(product.isForSell
      ? {
          [ProductDetailsTabKeys.PRICES]: {
            eventKey: ProductDetailsTabKeys.PRICES,
            title: 'Sales Prices',
            tabContent: (
              <ProductPriceList
                product={product}
                allowDelete
                allowEdit
                allowCreate
                onSaved={() => onSaved && onSaved(product)}
              />
            ),
          },
        }
      : {}),
    ...(product.isForPurchase
      ? {
          [ProductDetailsTabKeys.SUPPLIERS]: {
            eventKey: ProductDetailsTabKeys.SUPPLIERS,
            title: ProductDetailsTabKeys.SUPPLIERS,
            tabContent: (
              <ProductDetailsSuppliersPanel
                product={product}
                allowDelete={true}
              />
            ),
          },
        }
      : {}),
  };
};

const ProductDetailsPanelHelper = {
  getTabItemsMap,
};

export default ProductDetailsPanelHelper;

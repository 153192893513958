import React from 'react';
import { iComponentWithPageHeader } from '../common/ComponentWithPageHeader';
import ComponentWithPageHeader from '../common/ComponentWithPageHeader';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import PurchaseOrderItemService from '../../services/purchase/PurchaseOrderItemService';
import iPurchaseOrderItem from '../../types/purchase/iPurchaseOderItem';
import UtilsService from '../../services/UtilsService';
import { iTableColumn, iCellParams } from '../../helpers/DynamicTableHelper';

type iPurchaseOrderItemListProps = iComponentWithPageHeader & {
  allowDelete?: boolean;
  orderId?: string;
};

const PurchaseOrderItemList = ({
  headerProps,
  allowDelete,
  orderId,
}: iPurchaseOrderItemListProps) => {
  const { renderDataTable, renderDeleteBtn } =
    useListCrudHook<iPurchaseOrderItem>({
      getFn: (params) =>
        PurchaseOrderItemService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(orderId ? { orderId } : {}),
          }),
          include: 'Product,TaxRate',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 100,
        }),
    });

  const columns: iTableColumn<iPurchaseOrderItem>[] = [
    {
      key: 'product',
      header: 'Product Code / SKU',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) => (
        <div className="text-blue-600">{data.supplierProductCode || ''}</div>
      ),
    },
    {
      key: 'supplierProductCode',
      header: 'Supplier P/Code',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        data.supplierProductCode || '',
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        data.description || '',
    },
    {
      key: 'qty',
      header: 'Quantity',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) => `${data.qty} `,
    },
    {
      key: 'receivedQty',
      header: "Recv'd Qty",
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        `${data.receivedQty || 0} `,
    },
    {
      key: 'unitPrice',
      header: 'Unit Price',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        `$${data.unitPrice}  `,
    },
    {
      key: 'discountPercentage',
      header: 'Disc %',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        `${data.discountPercentage || 0} %`,
    },
    {
      key: 'tax',
      header: 'Tax',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        data.TaxRate?.name || 'GST',
    },
    {
      key: 'totalPrice',
      header: 'Total (Inc GST)',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        UtilsService.formatIntoCurrency(data.totalPrice || 0),
    },
    ...(allowDelete
      ? [
          {
            key: 'actions',
            header: 'Actions',
            isDefault: true,
            cell: ({ data }: iCellParams<iPurchaseOrderItem>) => {
              return (
                <div data-testid={`delete-icon-btn-${data.id}`}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      PurchaseOrderItemService.deactivate(data.id),
                    getDisplayName: (item) =>
                      item.supplierProductCode || 'Purchase Item',
                  })}
                </div>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      {renderDataTable({
        columns,
        tblProps: {
          testId: 'purchase-order-item-table',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default PurchaseOrderItemList;

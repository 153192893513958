import React from 'react';
import { Link } from 'react-router-dom';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import SearchTextField from '../frameWork/SearchTextField';
import {
  URL_CONTACT_COMPANY_DETAILS,
  URL_PURCHASE_ORDER_DETAILS,
} from '../../helpers/UrlMap';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import ContactCompanySelector from '../contact/ContactCompanySelector';
import iContactCompany, {
  ContactCompanyTypes,
} from '../../types/contact/iContactCompany';
import { iOptionWithData } from '../frameWork/Select';
import { DatePicker, formatDate } from '../frameWork/DateTimePicker';
import moment from 'moment';
import UtilsService from '../../services/UtilsService';
import iPurchaseOrder from '../../types/purchase/iPurchaseOder';
import EntityStatusLozenge from '../entityStatus/EntityStatusLozenge';

type iPurchaseOrderList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const PurchaseOrderList = ({
  allowDelete,
  headerProps,
}: iPurchaseOrderList) => {
  const {
    renderDataTable,
    renderDeleteBtn,
    renderEntityEditPopBtn,
    onSetFilter,
  } = useListCrudHook<iPurchaseOrder>({
    sort: 'createdAt:DESC',
    getFn: (params) =>
      PurchaseOrderService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,Status.Category,Supplier',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (purchase?: iPurchaseOrder) =>
    renderEntityEditPopBtn<iPurchaseOrder>({
      editingEntity: purchase,
      entityName: 'PurchaseOrder',
      createFn: (data) =>
        PurchaseOrderService.create({
          ...data,
          orderDate: data.orderDate || new Date().toISOString(),
        }),
      updateFn: PurchaseOrderService.update,
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className="cursor-pointer"
          data-testid={`purchase-order-${entity?.id || ''}`}
        >
          {entity?.orderNumber || 'NEW ORDER'}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'supplierId',
          label: 'Supplier',
          isRequired: true,
          isDisabled,
          value: entity?.supplierId || '',
          testId: 'supplierId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <ContactCompanySelector
                {...props}
                {...errorProps}
                contactCompanyType={ContactCompanyTypes.SUPPLIER}
                onChange={(option: iOptionWithData<iContactCompany> | null) => {
                  useAsForm.onFieldChange('supplierId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'supplierRef',
          label: 'Supplier Ref No.',
          isDisabled,
          value: entity?.supplierRef || '',
          testId: 'supplierRef',
        },
        {
          fieldName: 'orderDate',
          label: 'Order Date',
          isDisabled,
          value: (entity?.orderDate as string) || new Date().toISOString(),
          testId: 'orderDate',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <DatePicker
                {...props}
                {...errorProps}
                value={props.value as string}
                onChange={(selected) => {
                  useAsForm.onFieldChange(
                    fieldName,
                    `${selected || ''}`.trim() === ''
                      ? null
                      : moment(`${selected || ''}`.trim()).toISOString(),
                  );
                }}
              />
            );
          },
        },
      ],
    });

  const getColumns = (): iTableColumn<iPurchaseOrder>[] => [
    {
      key: 'orderNumber',
      header: 'Order No',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrder>) => (
        <Link to={URL_PURCHASE_ORDER_DETAILS.replace(':id', data.id)}>
          {data.orderNumber}
        </Link>
      ),
    },

    {
      key: 'status',
      header: 'Status',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrder>) => (
        <EntityStatusLozenge status={data.Status} />
      ),
    },

    {
      key: 'supplierRef',
      header: 'Reference',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrder>) => data.supplierRef || '',
    },

    {
      key: 'supplier',
      header: 'Supplier',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrder>) => {
        const supplierId = `${data.supplierId || ''}`.trim();
        if (!supplierId) return null;
        return (
          <Link
            to={URL_CONTACT_COMPANY_DETAILS.replace(':id', supplierId)}
            // data-testid={`supplier-link-${supplierId}`}
          >
            {data.Supplier?.name || ''}
          </Link>
        );
      },
    },

    {
      key: 'orderDate',
      header: 'Order Date',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrder>) =>
        formatDate(data.orderDate, 'DD MMM YYYY'),
    },

    {
      key: 'eta',
      header: 'ETA',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrder>) =>
        formatDate(data.eta, 'DD MMM YYYY'),
    },

    {
      key: 'totalAmt',
      header: 'Total Amt',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrder>) =>
        UtilsService.formatIntoCurrency(data.totalAmt),
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iPurchaseOrder>(),
    ...(allowDelete
      ? [
          {
            key: 'btns',
            header: 'Actions',
            isDefault: true,
            cell: ({ data }: iCellParams<iPurchaseOrder>) =>
              renderDeleteBtn({
                deletingModel: data,
                deleteFnc: async () => PurchaseOrderService.deactivate(data.id),
                getDisplayName: (po) => `Order #${po.orderNumber}`,
              }),
          },
        ]
      : []),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
        actions: (
          <SearchTextField
            testId={`purchase-list-search`}
            placeholder="Search P.O # or Reference"
            onSearch={(searchText) => {
              const txt = searchText.trim();
              if (!txt) {
                onSetFilter({});
              } else {
                onSetFilter({
                  [OP_OR]: [
                    { orderNumber: { [OP_LIKE]: `%${txt}%` } },
                    { supplierRef: { [OP_LIKE]: `%${txt}%` } },
                  ],
                });
              }
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.PURCHASE_ORDER_LIST,
        tblProps: {
          testId: 'purchase-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default PurchaseOrderList;
